enum RoomId {
  room1 = 'room1',
  room2 = 'room2',
  room3 = 'room3',
  room5 = 'room5',
  room4 = 'room4',
  room7 = 'room7',
  room6 = 'room6',
  room8 = 'room8',
  room9 = 'room9',
  room10 = 'room10',
  room11 = 'room11',
  room12 = 'room12',
  room13 = 'room13',
  room14 = 'room14',
  room15 = 'room15',
  room16 = 'room16',
  room17 = 'room17',
  room18 = 'room18',
  room19 = 'room19',
  room20 = 'room20',
  room21 = 'room21',
  room22 = 'room22',
  room23 = 'room23',
  room24 = 'room24',
  room25 = 'room25',
  room26 = 'room26',
  room27 = 'room27',
  room28 = 'room28',
  room29 = 'room29',
  room30 = 'room30',
  room31 = 'room31',
  room32 = 'room32',
  room33 = 'room33',
  room34 = 'room34',
  room35 = 'room35',
}


const ROOMING_MAP: Record<RoomId, string> = {
  [RoomId.room1]: "Květivoný",
  [RoomId.room2]: "Němčice",
  [RoomId.room3]: "Paříž",
  [RoomId.room4]: "Praha",
  [RoomId.room5]: "Dublin",
  [RoomId.room6]: "Londýn",
  [RoomId.room7]: "Benátky",
  [RoomId.room8]: "Řím",
  [RoomId.room9]: "Sydney",
  [RoomId.room10]: "Berlín",
  [RoomId.room11]: "New York",
  [RoomId.room12]: "San Francisco",
  [RoomId.room13]: "Las Vegas",
  [RoomId.room14]: "Rio de Janeiro",
  [RoomId.room15]: "Istanbul",
  [RoomId.room16]: "Tokio",
  [RoomId.room17]: "Asie",
  [RoomId.room18]: "Evropa",
  [RoomId.room19]: "Afrika",
  [RoomId.room20]: "Languedoc",
  [RoomId.room21]: "Domek 21",
  [RoomId.room22]: "Domek 22",
  [RoomId.room23]: "Sudový apartmán 1",
  [RoomId.room24]: "Sudový apartmán 2",
  [RoomId.room25]: "Sudový apartmán 3",
  [RoomId.room26]: "Sudový apartmán 4",
  [RoomId.room27]: "Obytný sud A",
  [RoomId.room28]: "Obytný sud B",
  [RoomId.room29]: "Obytný sud 5",
  [RoomId.room30]: "Obytný sud 6",
  [RoomId.room31]: "Obytný sud 7",
  [RoomId.room32]: "Obytný sud 8",
  [RoomId.room33]: "Maringotka Karl 1",
  [RoomId.room34]: "Maringotka Karl 2",
  [RoomId.room35]: "Maringotka Karl 3",
};

const BED_COUNT_MAP: Record<RoomId, string> = {
  [RoomId.room1]: "dvojlůžko",
  [RoomId.room2]: "dvojlůžko + 1 lůžko",
  [RoomId.room3]: "dvojlůžko",
  [RoomId.room4]: "2 oddělená lůžka",
  [RoomId.room5]: "2 oddělená lůžka",
  [RoomId.room6]: "2 oddělená lůžka",
  [RoomId.room7]: "2 oddělená lůžka",
  [RoomId.room8]: "2 oddělená lůžka",
  [RoomId.room9]: "dvojlůžko + 1 lůžko",
  [RoomId.room10]: "dvojlůžko + 1 lůžko",
  [RoomId.room11]: "dvojlůžko + 1 lůžko",
  [RoomId.room12]: "dvojlůžko + 1 lůžko",
  [RoomId.room13]: "dvojlůžko + 1 lůžko",
  [RoomId.room14]: "dvojlůžko + 1 lůžko",
  [RoomId.room15]: "dvojlůžko",
  [RoomId.room16]: "dvojlůžko",
  [RoomId.room17]: "dvojlůžko",
  [RoomId.room18]: "2 oddělená lůžka",
  [RoomId.room19]: "4 oddělená lůžka",
  [RoomId.room20]: "dvojlůžko",
  [RoomId.room21]: "dvojlůžko + 2 lůžka",
  [RoomId.room22]: "dvojlůžko + 2 lůžka",
  [RoomId.room23]: "dvojlůžko + 1 lůžko",
  [RoomId.room24]: "dvojlůžko + 1 lůžko",
  [RoomId.room25]: "dvojlůžko + 1 lůžko",
  [RoomId.room26]: "dvojlůžko + 1 lůžko",
  [RoomId.room27]: "2 oddělená lůžka",
  [RoomId.room28]: "2 oddělená lůžka",
  [RoomId.room29]: "2 oddělená lůžka",
  [RoomId.room30]: "2 oddělená lůžka",
  [RoomId.room31]: "2 oddělená lůžka",
  [RoomId.room32]: "2 oddělená lůžka",
  [RoomId.room33]: "dvojlůžko + 1 lůžko",
  [RoomId.room34]: "dvojlůžko + 1 lůžko",
  [RoomId.room35]: "dvojlůžko + 1 lůžko",
};

const PRICE_MAP: Record<RoomId, number> = {
  [RoomId.room1]: 2050,
  [RoomId.room2]: 2550,
  [RoomId.room3]: 2050,
  [RoomId.room4]: 2050,
  [RoomId.room5]: 2050,
  [RoomId.room6]: 2050,
  [RoomId.room7]: 2050,
  [RoomId.room8]: 2050,
  [RoomId.room9]: 2550,
  [RoomId.room10]: 2550,
  [RoomId.room11]: 2550,
  [RoomId.room12]: 2550,
  [RoomId.room13]: 2550,
  [RoomId.room14]: 2550,
  [RoomId.room15]: 2050,
  [RoomId.room16]: 2050,
  [RoomId.room17]: 2050,
  [RoomId.room18]: 2050,
  [RoomId.room19]: 2850,
  [RoomId.room20]: 2050,
  [RoomId.room21]: 2850,
  [RoomId.room22]: 2850,
  [RoomId.room23]: 3150,
  [RoomId.room24]: 3150,
  [RoomId.room25]: 3150,
  [RoomId.room26]: 3150,
  [RoomId.room27]: 1850,
  [RoomId.room28]: 1850,
  [RoomId.room29]: 1850,
  [RoomId.room30]: 1850,
  [RoomId.room31]: 1850,
  [RoomId.room32]: 1850,
  [RoomId.room33]: 3150,
  [RoomId.room34]: 3150,
  [RoomId.room35]: 3150,
};

export { ROOMING_MAP, BED_COUNT_MAP, PRICE_MAP, RoomId }