import React from 'react'
import emptyContentCss from './empty-content-css'

type Props = {
  children: any,
}

const EmptyContent: React.FC<Props> = ({ children }) => (
  <div className={emptyContentCss.wrapper()}>
    {children}
  </div>
)

export default EmptyContent
