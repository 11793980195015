import React from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import giftsCss from './gifts-css'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'

const Gifts: React.FC = () => (
  <div className={giftsCss.container()}>
    <NavBar />

    <PageTitle marginTop>Nevíte si rady s darem pro nás?</PageTitle>

    <Section>
      <div className={giftsCss.wrapper()}>
        <p className={giftsCss.info()}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
    </Section>
    <div className={giftsCss.whiteSpace()}></div>
    <Footer />
  </div>
)

export default Gifts
