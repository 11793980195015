import React from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import PageTitle from '../../components/page-title/page-title'
import EmptyContent from '../../components/empty-content/empty-content'
import Section from '../../components/section/section'

const Photo: React.FC = () => (
  <>
    <NavBar />
    <PageTitle marginTop>Fotografie</PageTitle>
    <Section isBackgroundBlue>
      {/* 1. TEMPORARY (remove) */}
      <EmptyContent>Po svatbě doplníme fotky od fotografa :)</EmptyContent>

      {/* 2. CONTENT
      <div>Fotky</div> */}

    </Section>

    <PageTitle >Videa</PageTitle>
    <Section isBackgroundBlue>
      {/* 1. TEMPORARY (remove) */}
      <EmptyContent>Po svatbě doplníme videa od kameramana :)</EmptyContent>

      {/* 2. CONTENT
      <div>Videa</div> */}
    </Section>
    <Footer />
  </>
)

export default Photo
