import { css } from '@emotion/css'

const giftsCss = {
  container: () => css({
    display: "flex",
    flexDirection: "column",
    minHeight: " 100vh",
  }),

  wrapper: () => css({
    display: "flex",
    justifyContent: "center",
  }),

  info: () => css({
    width: 800,
    maxWidth: "100%",
    textAlign: "center",
  }),

  whiteSpace: () => css({
    // NOTE is set to display the footer on the bottom edge of the page
    flex: 1,
  }),
}

export default giftsCss
