import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const emptyContentCss = {
  wrapper: () => css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed ${appSettings.colors.blueColor}`,
    height: 300,
    fontFamily: "SourceSerifProRegular",
    fontSize: 16,
    padding: 20,
  }),
}

export default emptyContentCss
