import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const footerCss = {
  wrapper: () => css({
    color: appSettings.colors.blueColor,
    textAlign: "center",
  }),
}

export default footerCss
