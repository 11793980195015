import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const sectionCss = {
  wrapper: ({ isBackgroundBlue }: { isBackgroundBlue?: boolean }) => css({
    backgroundColor: isBackgroundBlue ? appSettings.colors.blueBackground : undefined,

    [appSettings.mediaQueries.largeDesktop]: {
      padding: "30px 60px",
    },

    [appSettings.mediaQueries.desktop]: {
      padding: "30px 50px",
    },

    [appSettings.mediaQueries.largeTablet]: {
      padding: "30px 40px",
    },

    [appSettings.mediaQueries.smallTablet]: {
      padding: "30px 35px",
    },

    [appSettings.mediaQueries.mobile]: {
      padding: 25,
    },
  }),
}

export default sectionCss
