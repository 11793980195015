import { RoomId } from "../pages/accommodation/rooming-list";

export const attendOption: Record<
  "ceremony" | "ceremonyAndLunch" | "toTheEnd" | "default",
  { text: string; value: number }
> = {
  default: {
    text: "-- Vyberte možnost --",
    value: 0,
  },
  ceremony: {
    text: "Obřadu",
    value: 1,
  },
  ceremonyAndLunch: {
    text: "Obřadu a oběda",
    value: 2,
  },
  toTheEnd: {
    text: "Budu od začátku do konce",
    value: 3,
  },
};

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  attend: number;
  accommodation: RoomId;
  noAccommodation: boolean;
  notes: string;
};

export const submitForm = async (data: FormData): Promise<boolean> => {
  try {
    console.log("Data to submit", JSON.stringify(data));

    return true;
  } catch (error) {
    console.error(
      "Firestore failed to post message. Error from firebase:",
      error
    );

    return false;
  }
};

export const getPreFilledForm = async (
  code: string
): Promise<FormData | null> => {
  if (code)
    return {
      firstName: "Tomáš",
      lastName: "Hampl",
      email: "tomas90hampl@gmail.com",
      attend: 3,
      accommodation: RoomId.room1,
      noAccommodation: false,
      notes: "Příliš žluťoučký kůň úpěl ďábelské ódy",
    };

  return null;
};
