import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const accommodationCss = {
  headerWrapper: () => css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: appSettings.navFullHeight,
    position: "relative",
    width: "100%",
    height: "100%",
    maxHeight: 450,

    [appSettings.mediaQueries.largeDesktop]: {
      maxHeight: 550,
    },

    [appSettings.mediaQueries.largeTablet]: {
      maxHeight: 370,
    },

    [appSettings.mediaQueries.smallTablet]: {
      maxHeight: 300,
    },

    [appSettings.mediaQueries.mobile]: {
      maxHeight: 250,
    },
  }),

  header: () => css({
    fontSize: 60,
    position: "absolute",
    zIndex: 10,
    color: "white",
    textShadow: `2px 2px 6px rgba(0, 0, 0, 0.3)`,
    fontFamily: "CinzelRegular",

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 70,
    },

    [appSettings.mediaQueries.largeTablet]: {
      fontSize: 50,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 40,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 30,
    },
  }),

  vigvamFoto: () => css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  }),

  infoWrapper: () => css({
    height: 300,
  }),
}

export default accommodationCss
