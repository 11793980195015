import React from 'react'
import footerCss from './footer-css'
import Section from '../section/section'

const Footer: React.FC = () => (
  <Section>
    <div className={footerCss.wrapper()}>
      Tento web vytvořila Martina Vilímová a Tomáš Hampl. &#169; 2024
    </div>
  </Section>
)

export default Footer
