import React, { useEffect } from 'react'
import { css } from '@emotion/css'

const Css = {
  map: () => css({
    width: "100%",
    height: 300,
    background: "red"
  }),
}

const Map: React.FC = () => {
  // useEffect(() => {
    // Inicializace mapy, jakmile je načtena Google Maps API knihovna
  //   const initMap = () => {
  //     const vigvamResort = { lat: 50.0282192, lng: 15.4770675 }; // Souřadnice pro Resort Vigvam

  //     const map = new window.google.maps.Map(document.getElementById("map"), {
  //       zoom: 15,
  //       center: vigvamResort,
  //     });

  //     new window.google.maps.Marker({
  //       position: vigvamResort,
  //       map,
  //       title: "Resort Vigvam",
  //     });
  //   };

  //   // Dynamické načtení Google Maps API
  //   if (!window.google) {
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=TVŮJ_API_KLÍČ&callback=initMap`;
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = initMap;
  //     document.head.appendChild(script);
  //   } else {
  //     initMap();
  //   }
  // }, []);

  return (
    <div id="map" className={Css.map()}>Google map</div>
  )
}

export default Map
