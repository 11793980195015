import { css, keyframes } from '@emotion/css'
import appSettings from '../../app/app-settings'

const animateStart = keyframes({
  "0%": { opacity: 0, transform: "translateY(-16px)", },
  "100%": { opacity: 1, transform: "translateY(0)", }
})

const animateEnd = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)", },
  "100%": { opacity: 0, transform: "translateY(-16px)", }
})

const navBarCss = {
  wrapper: ({ shadow }: { shadow: Boolean }) => css({
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    alignItems: "center",
    height: appSettings.navFullHeight,
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 999,
    backgroundColor: appSettings.colors.whiteBackground,
    boxShadow:
      shadow ? `8px 0 8px ${appSettings.colors.blackShadow}` : 'none',
    transition: "box-shadow 0.5s ease",

    [appSettings.mediaQueries.smallTablet]: {
      gridTemplateColumns: "1fr 1fr",
    }
  }),

  logo: () => css({
    paddingLeft: 24,
    fontFamily: "CinzelRegular",
    fontSize: 20,
    color: appSettings.colors.blackColor,
  }),

  ampersand: () => css({
    fontSize: 16,
    paddingInline: 2,
    color: appSettings.colors.blueColor,
  }),

  desktopNav: () => css({
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",

    [appSettings.mediaQueries.smallTablet]: { display: 'none' }
  }),

  desktopLink: ({ active }: { active: Boolean }) => css({
    fontFamily: "CinzelRegular",
    paddingRight: 24,
    color: active ? appSettings.colors.blueColor : appSettings.colors.blackColor,
    "&:hover": { color: appSettings.colors.blueColor }
  }),

  mobileNav: () => css({
    display: "none",

    [appSettings.mediaQueries.smallTablet]: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
    }
  }),

  burger: ({ openMenu }: { openMenu: Boolean }) => css({
    display: "none",

    [appSettings.mediaQueries.smallTablet]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      marginRight: 24,
      padding: 0,

      "& > div": {
        width: 28,
        height: 1,
        margin: "3px 0px",
        backgroundColor: appSettings.colors.blackColor,
        borderRadius: 16,
        transformOrigin: 1,
        transition: "all 0.4s ease",
      },

      "div:nth-child(1)": {
        transform: openMenu ? 'rotate(34deg)' : 'rotate(0)',
        backgroundColor: openMenu ? appSettings.colors.blueColor : appSettings.colors.blackColor,
      },

      "div:nth-child(2)": {
        opacity: openMenu ? 0 : 1,
      },

      "div:nth-child(3)": {
        transform: openMenu ? 'rotate(-34deg)' : 'rotate(0)',
        backgroundColor: openMenu ? appSettings.colors.blueColor : appSettings.colors.blackColor,
      },
    }
  }),

  overlay: ({ openMenu }: { openMenu: Boolean }) => css({
    display: "none",

    [appSettings.mediaQueries.smallTablet]: {
      display: openMenu ? 'flex' : 'none',
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      position: "absolute",
      backgroundColor: appSettings.colors.whiteBackground,
      borderTop: `0.5px solid ${appSettings.colors.blueColor}`,
      top: appSettings.navFullHeight,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 998,
    }
  }),

  mobileLink: ({ active, visible }: { active: Boolean, visible: Boolean }) => css({
    fontFamily: "CinzelRegular",
    color: active ? appSettings.colors.blueColor : appSettings.colors.blackColor,
    marginRight: 24,
    marginTop: 16,
    "&:hover": { color: appSettings.colors.blueColor },
    opacity: visible ? 0 : 1,
    animation: `${visible ? animateStart : animateEnd} 1s ease forwards`,

    "&:nth-child(1)": {
      animationDelay: visible ? '0s' : '1s',
    },
    "&:nth-child(2)": {
      animationDelay: visible ? '0.2s' : '0.8s',
    },
    "&:nth-child(3)": {
      animationDelay: visible ? '0.4s' : '0.6s',
    },
    "&:nth-child(4)": {
      animationDelay: visible ? '0.6s' : '0.4s',
    },
    "&:nth-child(5)": {
      animationDelay: visible ? '0.8s' : '0.2s',
    },
    "&:nth-child(6)": {
      animationDelay: visible ? '1s' : '0s',
    },
  }),
}

export default navBarCss
