import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const programCss = {
  timeline: () => css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),

  timelineItem: () => css({
    display: "flex",
    alignItems: "center",
    gap: 28,
    width: 800,
    maxWidth: "100%",
    padding: 12,

    [appSettings.mediaQueries.smallTablet]: {
      gap: 20,
    },
  }),

  time: () => css({
    fontSize: 26,
    fontFamily: "CinzelRegular",
    whiteSpace: "nowrap",

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 22,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 18,
    },
  }),

  icon: () => css({
    height: 70,
    width: 70,
    objectFit: "contain",

    [appSettings.mediaQueries.smallTablet]: {
      height: 55,
      width: 55,
    },

    [appSettings.mediaQueries.mobile]: {
      height: 45,
      width: 45,
    },
  }),

  name: () => css({
    fontSize: 24,
    fontFamily: "CinzelRegular",
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 20,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 18,
    },
  }),

  description: () => css({
    fontSize: 18,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 14,
    },
  }),
}

export default programCss
