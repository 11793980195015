import { css as emotionCss } from '@emotion/css'

const appSettings = {
  navFullHeight: 60,
  navShrunkHeight: 40,

  mediaQueries: {
    largeDesktop: "@media only screen and (min-width: 1701px)",
    desktop: "@media only screen and (max-width: 1700px)",
    largeTablet: "@media only screen and (max-width: 1000px)",
    smallTablet: "@media only screen and (max-width: 750px)",
    mobile: "@media only screen and (max-width: 530px)",
  },

  colors: {
    // text
    blackColor: "#212529", // TODO
    blueColor: "#326e92",

    // background
    blueBackground: "#eef9ff",
    whiteBackground: "#ffffff", // TODO

    // shadow
    blackShadow: "#dadada",
  },

  css: {
    blueText: () => emotionCss({
      color: appSettings.colors.blueColor
    }),
  }
}

export default appSettings;
