import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const homeHeaderCss = {
  wrapper: () => css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: appSettings.navFullHeight,
    height: 350,
    paddingTop: 30,
    gap: 30,
    background: `url("assets/background.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",

    [appSettings.mediaQueries.largeDesktop]: {
      height: 460,
      paddingTop: 40,
      gap: 55,
    },

    [appSettings.mediaQueries.smallTablet]: {
      height: 270,
    },

    [appSettings.mediaQueries.mobile]: {
      height: 370,
      paddingTop: 40,
    },
  }),

  leftImage: () => css({
    position: "absolute",
    bottom: 0,
    left: 0,
    maxHeight: "100%",
    maxWidth: "50%",
  }),

  rightImage: () => css({
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "rotateY(180deg)",
    maxHeight: "100%",
    maxWidth: "50%",

    [appSettings.mediaQueries.mobile]: {
      transform: "rotateX(180deg) rotateY(180deg)",
      top: 0,
      right: 0,
    }
  }),

  title: () => css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    gap: 30,
    zIndex: 10,

    [appSettings.mediaQueries.mobile]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 0,
    },
  }),

  name: () => css({
    fontFamily: "AlexBrushRegular",
    fontSize: 90,
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 110,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 70,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 60,
    },
  }),

  ampersand: () => css({
    fontFamily: "ParisienneRegular",
    fontSize: 60,
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 80,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 40,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 30,
    },
  }),

  date: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 65,
    color: appSettings.colors.blueColor,
    zIndex: 10,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 40,
    }
  }),

  countdownTimer: () => css({
    display: "flex",
    justifyContent: "center",
    gap: 30,
    backgroundColor: "white",
    boxShadow: `2px 2px 12px ${appSettings.colors.blackShadow}`,
    zIndex: 10,

    [appSettings.mediaQueries.largeDesktop]: {
      gap: 35,
    },

    [appSettings.mediaQueries.mobile]: {
      width: "100%",
      justifyContent: "space-between",
      gap: 0,
      marginTop: 25,
    },
  }),

  countdownTimerItem: () => css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    width: 90,
    height: 90,

    [appSettings.mediaQueries.largeDesktop]: {
      width: 120,
      height: 120,
    },

    [appSettings.mediaQueries.smallTablet]: {
      width: 70,
      height: 70,
    },

    [appSettings.mediaQueries.mobile]: {
      width: "20%",
      height: 70,
    },
  }),

  timerCount: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 25,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 30,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 20,
    },

    [appSettings.mediaQueries.mobile]: {
      fontSize: 18,
    },
  }),

  timerName: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 15,
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 18,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 14,
    },
  }),
}

export default homeHeaderCss
