import React from 'react'
import programCss from './program-css'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import PageTitle from '../../components/page-title/page-title'
import EmptyContent from '../../components/empty-content/empty-content'
import Section from '../../components/section/section'

type timelinePoint = {
  name: string;
  time: string;
  description: string;
};

const TIMELINE_MAP: Record<string, timelinePoint> = {
  "point1": {
    name: "Příjezd hostů",
    time: "10 : 00",
    description: "xxx",
  },
  "point2": {
    name: "Obřad",
    time: "11 : 00",
    description: "xxx",
  },
  // "point3": {
  //   name: "Focení s rodinou",
  //   time: "12 : 00",
  //   description: "xxx",
  // },
  // "point4": {
  //   name: "Oběd",
  //   time: "13 : 30",
  //   description: "xxx",
  // },
  // "point5": {
  //   name: "Krájení dortu",
  //   time: "15 : 00",
  //   description: "xxx",
  // },
  // "point6": {
  //   name: "'Házení' kytice",
  //   time: "16 : 00",
  //   description: "Házet kyticí se ve skutečnosti nebude, ale budou se stříhat stuhy.",
  // },
  // "point7": {
  //   name: "Hry",
  //   time: "17 : 00",
  //   description: "Volný program, hry s novomanžely",
  // },
  // "point8": {
  //   name: "První tanec",
  //   time: "18 : 00",
  //   description: "První tanec novomanželů",
  // },
}

const Program: React.FC = () => (
  <>
    <NavBar />
    <PageTitle marginTop>Co máme v plánu během dne?</PageTitle>
    <Section>
      <div className={programCss.timeline()}>
        {Object.keys(TIMELINE_MAP).map((it) => {
          return (
            <div key={it} className={programCss.timelineItem()}>
              <div className={programCss.time()}>{TIMELINE_MAP[it].time}</div>
              <img src="assets/icon.png" alt="icon" className={programCss.icon()} />
              <div>
                <p className={programCss.name()}>{TIMELINE_MAP[it].name}</p>
                <p className={programCss.description()}>{TIMELINE_MAP[it].description}</p>
              </div>
            </div>
          )
        })}
      </div>
    </Section>

    {/* 1. TEMPORARY (remove) */}
    <Section>
      <EmptyContent>Harmonogram celého dne doplníme hned jak budeme mít konečné rozhodnutí.</EmptyContent>
    </Section>
    <Footer />
  </>
)

export default Program
