import React from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import attendCss from './attend-css'
import { BED_COUNT_MAP, RoomId, ROOMING_MAP } from '../accommodation/rooming-list'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'
import { attendOption } from '../../service/service'

const Attend: React.FC = () => (
  <>
    <NavBar />
    <PageTitle marginTop>Potvrzení účasti</PageTitle>

    <Section>
      {/* guest */}
      <form action="xxx" method="post">
        <p className={attendCss.guest()}>1. Host</p>
        <div className={attendCss.gridForm()}>

          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="first-name">Jméno</label>
            <input className={attendCss.input()} type="text" id="first-name" name="first-name" required />
          </div>

          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="last-name">Příjmení</label>
            <input className={attendCss.input()} type="text" id="last-name" name="last-name" required />
          </div>

          <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
            <label className={attendCss.label()} htmlFor="email">Email</label>
            <input className={attendCss.input()} type="email" id="email" name="email" required />
          </div>

          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="attend">Zúčastním se</label>
            <select className={attendCss.input()} id="attend" name="attend" required>
              <option value={attendOption.default.value}>{attendOption.default.text}</option>
              <option value={attendOption.ceremony.value}>{attendOption.ceremony.text}</option>
              <option value={attendOption.ceremonyAndLunch.value}>{attendOption.ceremonyAndLunch.text}</option>
              <option value={attendOption.toTheEnd.value}>{attendOption.toTheEnd.text}</option>
            </select>
          </div>

          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="allergies">Alergie na nějaké jídlo?</label>
            <input className={attendCss.input()} type="text" id="allergies" name="allergies" placeholder='Ořechy, mléko...' required />
          </div>
        </div>

        <div  className={attendCss.gridForm()}>
          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="accommodation">Ubytování</label>
            <select className={attendCss.input()} id="accommodation" name="accommodation">
              <option value="room0">-- Vyberte možnost --</option>
              {Object.keys(ROOMING_MAP).map((room) => {
                let children = `${ROOMING_MAP[room as RoomId]} (${BED_COUNT_MAP[room as RoomId]})`
                return (<option key={room} value={room}>{children}</option>)
              })}
            </select>
          </div>

          <div className={attendCss.inputWrapper()}>
            <label className={attendCss.label()} htmlFor="noAccommodation">Nezůstanu přes noc</label>
            <input type="checkbox" id="noAccommodation" name="noAccommodation" />
          </div>

          <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
            <label className={attendCss.label()} htmlFor="notes">Otázky nebo nějaké zpráva pro novomanžele :)</label>
            <textarea className={attendCss.input()} id="notes" name="notes" rows={10}></textarea>
          </div>
        </div>

        <button type="submit" className={attendCss.input()}>Odeslat</button>
      </form>

    </Section>
    <Footer />
  </>
)

export default Attend
