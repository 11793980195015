import React, { useEffect, useState } from 'react'
import homeHeaderCss from './home-header-css'

const HomeHeader: React.FC = () => {
  const [timeRemaining, setTimeRemaining] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {

    const updateCountdown = () => {

      const now = new Date();
      const march30 = new Date(now.getFullYear(), 2, 30);
      const targetDate = new Date(now < march30 ? '2025-07-19T11:00:00' : '2025-07-19T12:00:00');
      const diff = targetDate.getTime() - now.getTime();

      if (diff > 0) {
        const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
        const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        setTimeRemaining({ months, days, hours, minutes, seconds });
      } else {
        setTimeRemaining({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={homeHeaderCss.wrapper()}>
      <img src="assets/flower.png" alt="flower" className={homeHeaderCss.leftImage()} />
      <img src="assets/flower.png" alt="flower" className={homeHeaderCss.rightImage()} />

      {/* names */}
      <div className={homeHeaderCss.title()}>
        <div className={homeHeaderCss.name()}>Martina</div>
        <div className={homeHeaderCss.ampersand()}>&</div>
        <div className={homeHeaderCss.name()}>Tomáš</div>
      </div>

      {/* date */}
      <div className={homeHeaderCss.date()}>19. 7. 2025</div>

      {/* countdown timer */}
      <div className={homeHeaderCss.countdownTimer()}>
        <div className={homeHeaderCss.countdownTimerItem()}>
          <div className={homeHeaderCss.timerCount()}>{timeRemaining.months}</div>
          <div className={homeHeaderCss.timerName()}>
            {(timeRemaining.months > 4 || timeRemaining.months === 0) ? "Měsíců" : timeRemaining.months === 1 ? "Měsíc" : "Měsíce"}
          </div>
        </div>
        <div className={homeHeaderCss.countdownTimerItem()}>
          <div className={homeHeaderCss.timerCount()}>{timeRemaining.days}</div>
          <div className={homeHeaderCss.timerName()}>
            {(timeRemaining.days > 4 || timeRemaining.days === 0) ? "Dní" : timeRemaining.days === 1 ? "Den" : "Dny"}
          </div>
        </div>
        <div className={homeHeaderCss.countdownTimerItem()}>
          <div className={homeHeaderCss.timerCount()}>{timeRemaining.hours}</div>
          <div className={homeHeaderCss.timerName()}>
            {(timeRemaining.hours > 4 || timeRemaining.hours === 0) ? "Hodin" : timeRemaining.hours === 1 ? "Hodina" : "Hodiny"}
          </div>
        </div>
        <div className={homeHeaderCss.countdownTimerItem()}>
          <div className={homeHeaderCss.timerCount()}>{timeRemaining.minutes}</div>
          <div className={homeHeaderCss.timerName()}>
            {(timeRemaining.minutes > 4 || timeRemaining.minutes === 0) ? "Minut" : timeRemaining.minutes === 1 ? "Minuta" : "Minuty"}
          </div>
        </div>
        <div className={homeHeaderCss.countdownTimerItem()}>
          <div className={homeHeaderCss.timerCount()}>{timeRemaining.seconds}</div>
          <div className={homeHeaderCss.timerName()}>
            {(timeRemaining.seconds > 4 || timeRemaining.seconds === 0) ? "Sekund" : timeRemaining.seconds === 1 ? "Sekunda" : "Sekundy"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
