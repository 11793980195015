import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const cateringCss = {
  section: () => css({
    textAlign: "center",
    fontFamily: "CinzelRegular",
    fontSize: 16,

    "ul": {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 15,
    },
  }),

  seatingPlanFoto: () => css({
    width: 500,
    maxWidth: "100%",
  }),

  sectionTitle: () => css({
    color: appSettings.colors.blueColor,
    fontFamily: "SourceSerifProBold",
    fontSize: 20,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 18,
    },
  }),

  sectionSubtitle: () => css({
    color: appSettings.colors.blueColor,
    fontFamily: "SourceSerifProRegular",
    fontSize: 16,
    marginBottom: 14,
  }),

  lunchSubtitle: () => css({
    color: appSettings.colors.blueColor,
    fontFamily: "SourceSerifProBold",
    fontSize: 18,
    marginTop: 12,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 16,
    },
  }),
}

export default cateringCss
