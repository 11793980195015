import React from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import Map from './map'
import accommodationCss from './accommodation-css'
import Section from '../../components/section/section'

const Accommodation: React.FC = () => (
  <>
    <NavBar />
    {/* header */}
    <div className={accommodationCss.headerWrapper()}>
      <img src="assets/vigvam_1.jpg" alt="vigvam" className={accommodationCss.vigvamFoto()} />
      <p className={accommodationCss.header()}>Resort Vigvam</p>
    </div>

    {/* info */}
    <Section isBackgroundBlue>
      Informace o vigvamu
      <br />
      <br />
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      <br />
      <br />
      Parkování
    </Section>

    {/* accommodation */}
    <Section>
      Ubytování
      <br />
      <br />
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </Section>

    {/* rooming list */}
    <Section isBackgroundBlue>
      Informace o jednotlivých pokojích
      <br />
      <br />
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </Section>

    <Map />
    <Footer />
  </>
)

export default Accommodation
