import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import navBarCss from './nav-bar-css'

const menuList = [
  { to: '/accommodation', name: "Ubytování" },
  { to: '/catering', name: "Catering" },
  { to: '/program', name: "Program" },
  { to: '/photo', name: "Fotečky" },
  { to: '/gifts', name: "Dary" },
  { to: '/attend', name: "Účast" },
]

const NavBar: React.FC = () => {
  const [shadow, setShadow] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const location = useLocation()

  const animateStart = () => {
    setVisible(true)
    setOpenMenu(true)
  }

  const animateEnd = () => {
    setVisible(false)
    setTimeout(() => setOpenMenu(false), 1400)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setShadow(window.scrollY > 1))
  }, [])

  return (
    <>
      <div className={navBarCss.wrapper({ shadow })}>
        {/* left side - logo */}
        <Link to="/" className={navBarCss.logo()}>
          M
          <span className={navBarCss.ampersand()}>
            &
          </span>
          T
        </Link>

        {/* right side - nav for desktop */}
        <div className={navBarCss.desktopNav()}>
          {menuList.map((it) => (
            <Link key={it.name} to={it.to} className={navBarCss.desktopLink({ active: location.pathname === it.to })}>{it.name}</Link>
          ))}
        </div>

        {/* right side - nav for mobile */}
        <div className={navBarCss.mobileNav()}>
          <button className={navBarCss.burger({ openMenu })} onClick={() => (openMenu ? animateEnd() : animateStart())}>
            <div></div>
            <div></div>
            <div></div>
          </button>
        </div>
      </div>
      <div className={navBarCss.overlay({ openMenu })}>
        {menuList.map((it) => (
          <Link key={it.name} to={it.to} className={navBarCss.mobileLink({ active: location.pathname === it.to, visible })}>{it.name}</Link>
        ))}
      </div>
    </>
  )
}

export default NavBar
