import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const homeBodyCss = {
  wrapper: () => css({
    [appSettings.mediaQueries.mobile]: {
      marginTop: 70, // due to countdown timer
    }
  }),

  medallionWrapper: () => css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: appSettings.colors.blueBackground,

    [appSettings.mediaQueries.largeDesktop]: {
      padding: "60px 60px 30px 60px",
      gap: 60,
    },

    [appSettings.mediaQueries.desktop]: {
      padding: "50px 50px 30px 50px",
      gap: 60,
    },

    [appSettings.mediaQueries.largeTablet]: {
      padding: "50px 40px 30px 40px",
      gap: 40,
    },

    [appSettings.mediaQueries.smallTablet]: {
      flexDirection: "column",
      padding: "50px 35px 30px 35px",
      gap: 30,
    },

    [appSettings.mediaQueries.mobile]: {
      padding: 25,
      gap: 20,
    },
  }),

  medallion: () => css({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    textAlign: "center",
  }),

  medallionTitle: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 22,
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 25,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 20,
    },
  }),

  foto1: () => css({
    width: 200,
    height: 200,
    borderRadius: "50%",
  }),

  vigvamWrapper: () => css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",

    [appSettings.mediaQueries.largeDesktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.desktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.largeTablet]: {
      gap: 40,
    },

    [appSettings.mediaQueries.smallTablet]: {
      gridTemplateColumns: "1fr",
      gap: 30,
    },

    [appSettings.mediaQueries.mobile]: {
      gap: 20,
    },
  }),

  vigvamTitle: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 22,
    textAlign: "center",
    color: appSettings.colors.blueColor,
    marginBottom: 20,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 25,
    },

    [appSettings.mediaQueries.smallTablet]: {
      flexDirection: "column",
      fontSize: 20,
    },
  }),

  vigvam: () => css({
    display: "flex",
    flexDirection: "column",
  }),

  vigvamFoto: () => css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),

  colors: () => css({
    // TODO
    display: "flex",
    flexDirection: "column",
    height: 300,
    gap: 20,
  }),
}

export default homeBodyCss
