import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const attendCss = {
  guest: () => css({
    fontFamily: "CinzelRegular",
    color: appSettings.colors.blueColor,
    fontSize: 18,
    marginBottom: 8
  }),

  gridForm: () => css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
    padding: 20,
    backgroundColor: appSettings.colors.blueBackground,

    [appSettings.mediaQueries.mobile]: {
      gridTemplateColumns: "1fr",
    },
  }),

  inputWrapper: (inputParams: { gridColumn?: string } = {}) => css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    ...inputParams,

    [appSettings.mediaQueries.mobile]: {
      gridColumn: "1",
    },
  }),

  label: () => css({
    fontSize: 18,
  }),

  input: () => css({
    fontSize: 16,
    border: `1px solid ${appSettings.colors.blueColor}`,
    borderRadius: 8,
    padding: 8,
    outline: "none",

    "&:focus": {
      boxShadow: `0 0 5px ${appSettings.colors.blueColor}`,
    }
  }),
}

export default attendCss
