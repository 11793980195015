import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const pageTitleCss = {
  pageTitle: ({ marginTop }: { marginTop?: Boolean }) => css({
    marginTop: marginTop ? appSettings.navFullHeight: undefined,
    fontFamily: "CinzelRegular",
    fontSize: 28,
    textAlign: "center",
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 24,
    },
  }),
}

export default pageTitleCss
