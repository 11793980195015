import React from 'react'
import homeBodyCss from './home-body-css'
import Section from '../../components/section/section'

const HomeBody: React.FC = () => (
  <div className={homeBodyCss.wrapper()}>
    {/* medallion */}
    <div className={homeBodyCss.medallionWrapper()}>
      <div className={homeBodyCss.medallion()}>
        <p className={homeBodyCss.medallionTitle()}>Tomáš Hampl</p>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
      <img src="assets/foto_1.jpg" alt="foto-1" className={homeBodyCss.foto1()} />
      <div className={homeBodyCss.medallion()}>
        <p className={homeBodyCss.medallionTitle()}>Martina Vilímová</p>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
    </div>

    {/* vigvam */}
    <Section>
      <div className={homeBodyCss.vigvamWrapper()}>
        <img src="assets/vigvam_1.jpg" alt="vigvam" className={homeBodyCss.vigvamFoto()} />
        <div className={homeBodyCss.vigvam()}>
          <div className={homeBodyCss.vigvamTitle()}>Resort Vigvam</div>
          Vigvam - místo konání svatby....
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </Section>

    {/* colors */}
    <Section isBackgroundBlue>
      <div className={homeBodyCss.colors()}>
        Barvy svatby, dekorace, sladte se s námi....
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla est. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Pellentesque pretium lectus id turpis. Ut tempus purus at lorem. Cras elementum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
    </Section>
  </div>
)

export default HomeBody
