import { injectGlobal } from '@emotion/css'

injectGlobal`
  * {
    box-sizing: border-box;
  }
  @font-face {
    font-family: "AlexBrushRegular";
    src: url("fonts/alex-brush/AlexBrush-Regular.ttf");
    src: url("fonts/alex-brush/AlexBrush-Regular.woff");
  }
  @font-face {
    font-family: "CinzelRegular";
    src: url("fonts/cinzel/Cinzel-Regular.ttf");
    src: url("fonts/cinzel/Cinzel-Regular.woff");
  }
  @font-face {
    font-family: "SourceSerifProBold";
    src: url("fonts/source-serif-pro/SourceSerifPro-Bold.ttf");
    src: url("fonts/source-serif-pro/SourceSerifPro-Bold.woff");
  }
  @font-face {
    font-family: "SourceSerifProRegular";
    src: url("fonts/source-serif-pro/SourceSerifPro-Regular.ttf");
    src: url("fonts/source-serif-pro/SourceSerifPro-Regular.woff");
  }
  @font-face {
    font-family: "ParisienneRegular";
    src: url("fonts/parisienne/Parisienne-Regular.ttf");
    src: url("fonts/parisienne/Parisienne-Regular.woff");
  }
`

const appGlobalCss = {
  "html, body, #root ": {
    margin: 0,
    padding: 0,
    fontWeight: 300,
    fontFamily: "SourceSerifProRegular",
    height: "100%",
  },

  "h1, h2, h3, h4, h5, h6, p": {
    margin: 0,
  },

  "a, button": {
    cursor: "pointer",
    textDecoration: "none",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  }
}

export default appGlobalCss;
