import React from 'react'
import HomeHeader from './home-header'
import HomeBody from './home-body'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'

const Home: React.FC = () => (
  <>
    <NavBar />
    <HomeHeader />
    <HomeBody />
    <Footer />
  </>
)

export default Home
